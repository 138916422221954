import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import axios from 'axios';
import Vuelidate from 'vuelidate'
import VueAlertify from 'vue-alertify'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(VueAlertify)

Vue.config.productionTip = false

// Vue.prototype.$url = 'http://horrorsurvive.com/'
Vue.prototype.$url = 'https://api.horrorsurvive.com/'
// Vue.prototype.$url_admin = 'http://quizz.horrorsurvive.com/admin'
Vue.prototype.$url_admin = 'https://api.horrorsurvive.com/admin'

Vue.prototype.$http =  axios.create({
  baseURL: Vue.prototype.$url + 'api/',
  headers: { "Content-Type": "application/json"}
});

const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+token
}

new Vue({
  render: h => h(App),
  router
}).$mount('#app')