import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Login')
    },

    {
        path: '',
        name:'Layout',
        component: () => import(/* webpackChunkName: "Layout" */ '../components/Layout'),
        children: [

            {
                path: '/scenarios',
                name: 'Scenarios',
                component: () => import(/* webpackChunkName: "Scenarios" */ '../views/Scenarios'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: '/cadenas',
                name: 'Cadenas',
                component: () => import(/* webpackChunkName: "Cadenas" */ '../views/Cadenas')
            },
            {
                path: '/main',
                name: 'Main',
                component: () => import(/* webpackChunkName: "Main" */ '../views/Main'),
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: '/admin',
                name: 'Admin',
                component: () => import(/* webpackChunkName: "Admin" */ '../views/Admin')
            },
        ]
    }



]

const router = new VueRouter({
    routes,
    mode:'history'
})

export default router